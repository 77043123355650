@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

div{
  font-family: 'Pretendard-Regular';
}
input{
  font-family: 'Pretendard-Regular';
}

.main-header{
  width: 100%;
  height: 100px;
  padding: 20px 0px;
  text-align: center;
}
.main-header img{
  height: 100%;
}
.main-header span{
  color: rgb(193, 199, 235);
  display: block;
}

.main-back{
  width: 95%;
  margin: 30px auto;
  padding: 0px;
  max-width: 1500px;
  border-top: 1px solid rgb(193, 199, 235);
  border-bottom: 1px solid rgb(193, 199, 235);
  display: flex;
}


.main-left-div{
  width: 65%;
}
.map{
  width: 100%;
  height: 800px;
  position: relative;
}

.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 350px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 40px;
  cursor: pointer;
}

.selected-div{
  max-width: 150px;
}
.selected-div>div{
  margin-bottom: 2px;
}



.main-right-div{
  width: 35%;
}
.map-info{
  width: 100%;
  height: 800px;
  overflow: hidden;
  overflow-y: scroll;
}
.map-info::-webkit-scrollbar {
  display: none;
}

/* 디테일 카드 헤더 */
.detail-route-div-header{
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #7858f9;
  position: sticky;
  top: 0;
}

/* 디테일 카드 한개 전체 */
.detail-route-div{
  /* height: 100px; */
  padding: 5px 10px;
  transition: all 1s;
}


/* 아무것도 선택안했을때 */
.main-right_empty{
  margin-top: 350px;
  text-align: center;
}

.main-right_empty p:nth-child(1){
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -.3px;
  font-size: 16px;
}
.main-right_empty p:nth-child(2){
  padding-top: 4px;
  color: #858a8d;
  line-height: 1.43;
  letter-spacing: -.3px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.detail-route-div-step{
  display: flex;
}


/* 디테일 카드 왼쪽 박스 */
.detail-route-left{
  width: 35px;
}
.route-num{
  font-size: 16px;
  font-weight: bold;
  line-height: 1.8;
  height: 30px;
  color: #191BA9;
  border: 3px solid #191BA9;
  border-radius: 20px;
  text-align: center;
}
.route-num-line{
  height: 100%;
}
.route-num-line div{
  width: 0px;
  min-height: 30px;
  height: 100%;
  border: 1px solid #191BA9;
  margin: 0 auto;
}



/* 이동수단 아이콘 */
.move-icon-div{
  height: 20px;
  text-align: center;
}
.move-icon-div img{
  width: 20px;
  height: 20px;
}
.route-num-line-vertical{
  height: 0px;
  width: 10px;
  margin: 0 auto;
  border: 1px solid #191BA9 ;
}


/* 오른쪽 */
.detail-route-right{
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.travel-title{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.8;
  color: rgb(84, 105, 224);
}

.move-title{
  font-size: 18px;
  font-weight: bold;
  color: #858a8d;
}


.travel-detail-btn-back{
  display: flex;
}

.travel-detail-btn{
  width: 25px;
  color: rgb(84, 105, 224);
  font-size: 20px;
  margin-right: 4px;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.travel-detail-btn:hover{
  color: rgb(249, 84, 84);
}

.move-detail-btn{
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer;
}
.move-detail-btn:hover{
  color: rgb(249, 84, 84);
}


.detail-route-move-summary{
  font-size: 12px;
  color: #b2b7ba;
}



/* 펼쳤을때 나오는 창 */

.detail-route-hidden-div{
  display: none;
}
.detail-route-hidden-left{
  width: 35px;
}
.detail-route-hidden-num-line{
  height: 100%;
}
.detail-route-hidden-num-line div{
  width: 0px;
  height: 100%;
  border: 1px solid #191BA9;
  margin: 0 auto;
}
.detail-route-hidden-right{
  margin-left: 10px;
  width: 90%;
}
.detail-route-hidden-right>div{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.detail-route-hidden-sub-title{
  margin-right: 10px;
  color: #6669f0;
}

.memo-input{
  width: 50%;
}
/* .memo-sub-title{
} */
.memo-textarea{
  width: 50%;
  height: 60px;
}



/* 이동수단 히든창 */

.detail-route-hidden-move-title{
  margin-right: 10px;
  color: #858a8d;
}



/* 보여주기 상태 css */

.show-route-detail{
  display: flex;
}


